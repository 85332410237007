/* eslint-disable react/jsx-key */
import React from 'react';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

import { getWeekFromDateString, formatProductVolume, chainTypeToString } from 'services/Utils';

import ProductPricesTable from '../../../../components/products/pricetable/ProductPricesTable';

const contentStyle = {
  width: '80%',
  maxWidth: 'none',
};

const exportColumns = [
  {
    key: 'date',
    name: 'Datum',
    width: '10'
  },
  {
    key: 'week',
    name: 'Vecka',
    width: '10'
  },
  {
    key: 'store',
    name: 'Butik',
    width: '30'
  },
  {
    key: 'product',
    name: 'Produkt',
    width: '30'
  },
  {
    key: 'manufacturer',
    name: 'Varumärke',
    width: '20'
  },
  {
    key: 'gtin',
    name: 'GTIN',
    width: '20'
  },
  {
    key: 'volume',
    name: 'Volym',
    width: '10'
  },
  {
    key: 'price',
    name: 'Pris ord.',
    width: '10',
    t: 'n',
    z: '0.00'
  },
  {
    key: 'discountAmount',
    name: 'Kampanjpris',
    width: '10',
    t: 'n',
    z: '0.00'
  },
];

class PriceTableDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.products !== this.props.products) {
      let rows = [];
      const products = this.props.products;
      if (products || products.length) {
        products.forEach((product) => {
          rows = [...rows, ...this.createRows(product)];
        });
      }

      this.setState({ rows });
      this.forceUpdate();
    }
  }

  handleClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }
  
  createRows(product) {
    const rows = [];
    const stores = product.getActiveStores();

    stores.forEach((store) => {
      const prices = store.getProductPrices(product.id);
      let chain = (store.chainName || 'Övrigt');
      let storeName = store.name;
      if (chainTypeToString(store.type) != chain) {
        if (chain == 'MatHem') {
          chain = '';
        } else if(chainTypeToString(store.type) == 'Online') {
          chain = '';
          storeName += ' (Online)';
        }
        else {
          chain += ' ' + chainTypeToString(store.type)
        }
      }
      storeName = (chain + ' ' + storeName).trim();
      prices.map((innerPrice) => {
        innerPrice.forEach((price) => {
          const date = this.formatDate(price.date);
          rows.push({
            date,
            store: storeName,
            product: product.name,
            price: this.formatAmountToPrice(price.amount),
            discountAmount: this.formatAmountToPrice(price.discountAmount),
            volume: formatProductVolume(product.amountOfUnits, product.unit),
            gtin: product.gtin,
            manufacturer: product.varumarke,
            week: getWeekFromDateString(date), 
          });
        });
      });
    });

    return rows;
  }

  formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  formatAmountToPrice(amount) {
    if (amount) {
      const priceKr = amount / 100;
      return priceKr.toFixed(2);
    }
    return '';
  }

  exportToExcel() {
    const excelData = this.state.rows.map(r => {
      const rr = {};
      exportColumns.forEach(c => {
        rr[c.name] = r[c.key];
      });
      return rr;
    });

    const ws = XLSX.utils.json_to_sheet(excelData);
    const charIntA = 65;
    
    // set bold headers
    for (let i = 0; i < exportColumns.length; i++) {
      const col = `${String.fromCharCode(i+charIntA)}`;
      ws[`${col}1`].s = {font: {bold: true} };
      var cellType = exportColumns[i].t;
      var cellFormat = exportColumns[i].z;
      if (cellType) {
        for (let j = 0; j < excelData.length; j++) {
          const cell = `${col}${j+2}`;
          ws[cell].t = cellType;
          if (cellFormat) {
            ws[cell].z = cellFormat;
          }
        };
      }
    }
   
    // set column widths
    var wscols = exportColumns.map(c => {return {wch: c.width}});
    ws['!cols'] = wscols;

    const wb = { Sheets: { 'Pristabell': ws }, SheetNames: ['Pristabell'] };
    
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmI formats-officedocument. spreadsheetmI. sheet; charset-UTF-8'});
    FileSaver.saveAs(data, 'Pristabell.xlsx');
  }

  render() {
    return (
      <Dialog
        fullWidth
        maxWidth={'xl'}
        open={this.props.open || false}
        onClose={this.handleClose.bind(this)}
        scroll={'paper'}
      >
        <DialogTitle>Pristabell</DialogTitle>
        <ProductPricesTable
          columns={exportColumns}
          products={this.state.rows || []}
          height={'600px'}
        />
        <DialogActions>
          <Button color="primary" onClick={this.exportToExcel.bind(this)}>
            Exportera
          </Button>

          <Button primary onClick={this.handleClose.bind(this)}>
            Stäng
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PriceTableDialog;
