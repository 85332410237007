import React from 'react';
import Paper from '@mui/material/Paper';
import AmCharts from '@amcharts/amcharts3-react';
import Backend from 'services/Backend';
import { getFullProductNameSplit, chainTypeToString, getMaxGridCount, getWeekFromDateString, getShortDateWithWeek } from '../../../services/Utils';
class StorePricePlot extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    productData: [],
    plotLayout: {
      title: '',
      yaxis: {
        title: '',
        ticksuffix: '',
      },
      xaxis: {
        title: '',
      },
    },
    id: this.props.stores[0].id,
    fileName: this.props.stores.length == 1
      ? (this.props.stores[0].chainName + ' ' + this.props.stores[0].city)
      : ((this.props.stores[0].chainName || 'Övrigt') + ' ' + chainTypeToString(this.props.stores[0].type))
      + ' ' + new Date().toJSON().slice(0, 10),
    activeProductsCount: 0,
    graphs: [],
    plotData: [],
    weeksCount: 5,
  };

  componentDidMount() {
    if (this.props.stores) {
      if(this.props.stores.some(x => x.active)){
        this.parseDataForPlot();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.stores.length !== this.props.stores.length ||
      prevProps.showDiscountPrices !== this.props.showDiscountPrices ||
      prevProps.showComparePrices !== this.props.showComparePrices ||
      this.getActiveProductsFromStores(this.props.stores).length !==
        this.state.activeProductsCount
    ) {
      if(this.props.stores.some(x => x.active)){
        this.parseDataForPlot();
      }
    }
  }

  unitConvertion = {
    1: 'kg', // Weight units, converted to kilograms
    2: 'l', // Volume units, converted to liters
    3: 'st', // Singe units, not used for now
    4: 'm', // Length units, converted to meters
  };

  getActiveProductsFromStores(stores) {
    const allActiveProducts = stores.map(x => x.getActiveProducts());
    let uniqueProducts = allActiveProducts.flatMap(x => x);
    uniqueProducts = Array.from(uniqueProducts.reduce(
      (acc, val) => {
        if(!acc.has(val.id))
          acc.set(val.id, val);
        return acc
      },
      new Map()
    ).values());

    return uniqueProducts;
  }

  parseDataForPlot = () => {
    const stores = this.props.stores;
    const uniqueProducts = this.getActiveProductsFromStores(stores);
    const activeProducts = uniqueProducts;
    const productData = this.createDataForProducts(stores, activeProducts);
    const plotData = this.createPlotData(productData);
    const plotLayout = this.createLayoutForStore(stores);

    this.setState({
      activeProductsCount: activeProducts.length,
      stores: stores,
      productData,
      plotData,
      plotLayout,
      graphs: this.createGraphs(productData, stores[0], plotLayout),
    });
  };

  formatAmountToPrice(amount) {
    const amountFloat = parseFloat(amount);
    if (!isNaN(amountFloat)) {
      return (amountFloat / 100).toFixed(2);
    }
    return null;
  }

  formatLegendText(name, manufacturer, width) {
    name = name === null ? '' : name;
    manufacturer = manufacturer === null ? '' : manufacturer;

    const stringDivider = (str, w) => {
      if (str.length > w) {
        let p = w;
        while (p > 0 && !/\s/.test(str[p])) {
          p--;
        }
        if (p > 0) {
          const left = str.substring(0, p);
          const right = str.substring(p + 1);
          return left + '<br>' + stringDivider(right, w);
        }
      }
      return str;
    };

    if (name.length + manufacturer.length <= width - 2) {
      return name + ', ' + manufacturer;
    }

    const splitname =
      name.length > width ? stringDivider(name, width) + '<br>' : name + '<br>';
    const splitmanu =
      manufacturer.length > width
        ? stringDivider(manufacturer, width)
        : manufacturer;

    return splitname + splitmanu;
  }

  createDataForProducts(stores, activeProducts) {
    const returnData = [];
    const storeIds = new Set(...stores.map(x => x.id));
    const priceDates = activeProducts
      .flatMap((cp) => cp.prices.flatMap((p) => p))
      .filter((p) => storeIds.has(p.storeId))
      .flatMap((p) => {
        if (p.lastDateTime) {
          const db = new Date(p.lastDateTime);
          db.setDate(db.getDate() - 1);
          return [p.datetime, db.datetime, p.lastDateTime];
        }
        return [p.datetime];
      })
      .flatMap((p) => p)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
      .map((x) => new Date(x))
      .sort((a, b) => {
        return a - b;
      });
    activeProducts.forEach((product) => {
      const allInnerPrices = stores.map(x => product.getPricesByStore(x.id, priceDates));
      const innerPrices = this.averageInnerPrices(allInnerPrices);
      innerPrices.forEach((prices) => {
        const data = {};
        var splitName = getFullProductNameSplit(product);
        data.productName = splitName[0];
        if(data.productName.length > 23){
          data.productName = data.productName.substring(0,23) + '…';
        }
        data.name = this.formatLegendText(
          splitName[0],
          splitName[1],
          30
        ); // Legend 30 characters wide max
        data.line = {
          size: 10,
          shape: 'hv',
        };
        const xData = [];
        //const yData = [];
        const amChartData = [];
        const text = [];

        for (let i = 0; i < prices.length; i++) {
          let tempText = '';
          xData.push(prices[i].date);
          if (
            prices[i].discountAmount &&
            this.props.showDiscountPrices &&
            !this.props.showComparePrices
          ) {
            amChartData.push({
              date: prices[i].date,
              price: this.formatAmountToPrice(prices[i].discountAmount),
            });
            //yData.push(this.formatAmountToPrice(prices[i].discountAmount));
            tempText = 'Kampanjpris!';
            if (prices[i].onlyMembers) {
              tempText += ' Kortvara';
            }
          } else if (
            prices[i].compareDiscountAmount &&
            this.props.showDiscountPrices &&
            this.props.showComparePrices
          ) {
            amChartData.push({
              date: prices[i].date,
              price: this.formatAmountToPrice(prices[i].compareDiscountAmount),
            });
            //yData.push(
            //  this.formatAmountToPrice(prices[i].compareDiscountAmount)
            //);
            tempText = 'Kampanjpris!';
            if (prices[i].onlyMembers) {
              tempText += ' Kortvara';
            }
          } else if (this.props.showComparePrices && prices[i].compareAmount) {
            amChartData.push({
              date: prices[i].date,
              price: this.formatAmountToPrice(prices[i].compareAmount),
            });
            //yData.push(this.formatAmountToPrice(prices[i].compareAmount));
          } else {
            amChartData.push({
              date: prices[i].date,
              price: this.formatAmountToPrice(prices[i].amount),
            });
            //yData.push(this.formatAmountToPrice(prices[i].amount));
          }
          text.push(tempText);
        }
        data.visible = stores[0].active;
        data.amChartData = amChartData;
        data.text = text;
        returnData.push(data);
      });
    });
    return returnData;
  }

  averageInnerPrices(pricesArray) {
    if (pricesArray.length <= 1)
      return pricesArray[0];
    
    const flat = pricesArray.flatMap(p => p).flatMap(p => p).filter(p => p);
    const groupedByDate = {}
    flat.forEach(p => {
      if(!groupedByDate[p.date])
        groupedByDate[p.date] = [];
      groupedByDate[p.date].push(p);
    });

    const avgResult = [];
    Object.values(groupedByDate).forEach( v => {
      const avg = v[0].clone();
      const amount = v.filter(x => x.amount).map(x => x.amount)
        .reduce((acc, val) => [acc[0]+val, acc[1] + 1], [0,0]);
      const compareAmount = v.filter(x => x.compareAmount).map(x => x.compareAmount)
        .reduce((acc, val) => [acc[0]+val, acc[1] + 1], [0,0]);
      const discountAmount = v.filter(x => x.discountAmount).map(x => x.discountAmount)
        .reduce((acc, val) => [acc[0]+val, acc[1] + 1], [0,0]);
      const compareDiscountAmount = v.filter(x => x.compareDiscountAmount).map(x => x.compareDiscountAmount)
        .reduce((acc, val) => [acc[0]+val, acc[1] + 1], [0,0]);
      const onlyMembers = v.some(x => x.onlyMembers);
      
      avg.amount = amount[1] ? amount[0]/amount[1] : null;
      avg.compareAmount = compareAmount[1] ? compareAmount[0]/compareAmount[1] : null;
      avg.discountAmount = discountAmount[1] ? discountAmount[0]/discountAmount[1] : null;
      avg.compareDiscountAmount = compareDiscountAmount[1] ? compareDiscountAmount[0]/compareDiscountAmount[1] : null;
      avg.onlyMembers = onlyMembers;
      avgResult.push(avg);
    })

    return [avgResult];
  }

  createPlotData(productData) {
    const map = new Map();
    for (const data of productData) {
      this.createPlotDataHelper(data, map);
    }
    const result = [];
    const weeks = new Set();
    
    map.forEach((value, key) => {
      result.push({ date: key, ...value });
      weeks.add(key.substring(0,4) + getWeekFromDateString(key))
    });
    this.setState({
      weeksCount: weeks.size
    });

    return result;
  }

  parseDate(dateStr) {
    const parts = dateStr.split('-');
    return new Date(parts[2], parts[1] - 1, parts[0]);
  }

  createPlotDataHelper(data, map) {
    const amChartData = data.amChartData;
    const name = data.name;
    for (let i = 0; i < amChartData.length; i++) {
      if (map.has(amChartData[i].date)) {
        const currentValue = map.get(amChartData[i].date);
        const key = name.split(' ').join('');
        if (key in currentValue) {
          const oldValue = currentValue[key];
          const newValue =
            (parseFloat(oldValue) + parseFloat(amChartData[i].price)) / 2;
          currentValue[key] = newValue.toString();
          map.set(amChartData[i].date, currentValue);
        } else {
          const valueToAdd = { [key]: amChartData[i].price };
          const newValue = { ...currentValue, ...valueToAdd };
          map.set(amChartData[i].date, newValue);
        }
      } else {
        map.set(amChartData[i].date, {
          [name.split(' ').join('')]: amChartData[i].price,
        });
      }
    }
  }

  createLayoutForStore = (stores) => {
    let layout;
    let chain = (stores[0].chainName || 'Övrigt');
    if (chainTypeToString(stores[0].type) != chain) {
      chain += ' ' + chainTypeToString(stores[0].type);
    }
    let name = stores.length == 1
      ? chain + ' ' + stores[0].name
      : chain;
      
    if (this.props.showComparePrices && this.props.isSameUnit) {
      layout = this.getStandardLayout(this.getUnit(stores[0]));
      layout.title = name + ' - Visar jämförpris';
      return layout;
    }
    layout = this.getStandardLayout();
    layout.title = name;
    return layout;
  };

  // this.props.isSameUnit checks that this expression is valid
  getUnit(store) {
    var products = store.getActiveProducts();
    if(!products || products.length == 0){
      return null;
    }
    
    return products[0].prices[0][0].unit;
  }

  getStandardLayout = (unit) => {
    const margins = 48;
    const layout = {
      autosize: true,
      width: '98%',
      height: this.props.height || 300,
      showlegend: true,
      legend: {
        x: 100,
        y: 1,
        xAnchor: 'left',
        yAnchor: 'bottom',
        traceorder: 'normal',
      },
      margin: {
        r: margins,
        l: margins + 32,
        b: margins,
        t: margins + 48,
        pad: 1,
      },
      xaxis: {
        title: 'Datum',
      },
    };
    if (unit) {
      layout.yaxis = {
        title: 'Jämförpris',
        ticksuffix: ' kr/' + this.unitConvertion[unit],
      };
    } else {
      layout.yaxis = {
        title: 'Pris',
        ticksuffix: ' kr',
      };
    }
    return layout;
  };

  createGraphs(plotData, store, plotLayout) {
    const graphs = [];
    plotData.forEach((data) => {
      graphs.push(
        this.createGraphsHelper(data, store, plotLayout.yaxis.ticksuffix)
      );
    });
    return graphs;
  }

  createGraphsHelper(data, store, ticksuffix) {
    return {
      id: 'graph' + data.name.split(' ').join(''),
      balloon: {
        verticalPadding: 3,
      },
      balloonText: '<b>' + data.productName + '</b>: [[value]] ' + ticksuffix,
      type: 'step',
      lineThickness: 2,
      // "lineColor": store.color, what color shall be used?
      bullet: 'square',
      bulletAlpha: 0,
      bulletSize: 2,
      bulletBorderAlpha: 0,
      connect: false,
      title: data.name,
      valueField: data.name.split(' ').join(''),
    };
  }

  render() {
    const id = this.state.id;
    const fileName = this.state.fileName;
    return (
      <Paper elevation={0} className={this.props.className}>
        <AmCharts.React
          style={{
            // width: '100%',
            // height: '500px'
            width: this.state.plotLayout.width,
            height: this.state.plotLayout.height,
          }}
          options={{
            type: 'serial',
            hideCredits: true,
            titles: [{ text: this.state.plotLayout.title }],
            theme: 'light',
            autoMarginOffset: 25,
            dataProvider: this.state.plotData,
            graphs: this.state.graphs,
            chartCursor: {
              fullWidth: false,
              cursorAlpha: 0.05,
              graphBulletAlpha: 1,
            },
            dataDateFormat: 'YYYY-MM-DD',
            categoryField: 'date',
            categoryAxis: {
              autoGridCount: false,
              showFirstLabel: true,
              showLastLabel: true,
              minPeriod: 'DD',
              parseDates: false,
              equalSpacing: false,
              autoGridCount: false,
              gridCount: Math.min(this.state.weeksCount -2, getMaxGridCount()),
              categoryFunction: getShortDateWithWeek,
              gridAlpha: 0,
            },
            valueAxes: [
              {
                position: 'left',
                title: this.state.plotLayout.yaxis.title,
                unit: this.state.plotLayout.yaxis.ticksuffix,
                axisAlpha: 0,
              },
            ],
            legend: {
              useGraphSettings: true,
              align: 'left',
              autoMargins: true,
              position: 'right',
              valueText: '[[value]]' + this.state.plotLayout.yaxis.ticksuffix,
            },
            export: {
              enabled: true,
              fileName: fileName,
              removeImages: false,
              forceRemoveImages: false,
              legend: {
                position: 'top', // or "right", "bottom" and "left" are possible
                width: 200, // optional
                height: 200, // optional
              },
              position: 'bottom-right',
              afterCapture: function afterCapture(menuConfig) {
                const imgFromHtml = document.getElementById(id);
                imgFromHtml.crossOrigin = 'anonymous';
                const fabricImage = new fabric.Image(imgFromHtml, {
                  top: 5,
                  left: this.setup.fabric.getWidth() * 0.8,
                });
                this.setup.fabric.add(fabricImage);

                const weekNumber = (today) => {
                  const onejan = new Date(today.getFullYear(), 0, 1);
                  return Math.ceil(
                    ((today - onejan) / 86400000 + onejan.getDay() + 1) / 7
                  );
                };
                const text = new fabric.Text(
                  'Vecka ' + weekNumber(new Date()),
                  {
                    top: 15,
                    left: 15,
                    fontFamily: this.setup.chart.fontFamily,
                    fontSize: 15,
                    fontWeight: 'bold',
                  }
                );
                this.setup.fabric.add(text);
              },
            },
          }}
        />
      </Paper>
    );
  }
}

export default StorePricePlot;
